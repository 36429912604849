<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('administration.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label for="category">{{ $t('administration.table.fields.category') }}</label>
          <treeselect
            id="category"
            :value="categoryFilter"
            :multiple="false"
            :options="categoryOptions"
            :normalizer="normalizer"
            class="w-100"
            @input="(value) => $emit('update:categoryFilter', value)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label for="attribute_id">{{ $t('form.attribute_id.label') }}</label>
          <v-select
            id="attribute_id"
            v-model="attributeIdFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="attributesOptions"
            :reduce="val => val.value"
            :clearable="true"
            :input-id="'attribute_id'"
            @input="loadAttributeValue($event)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label for="attribute_value_id">{{ $t('form.attribute_id.label') }} - {{ $t('form.attribute_value_id.label') }}</label>
          <v-select
            id="attribute_value_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="attributeValuesOptions[`attribute_${attributeIdFilter}`]"
            :disabled="!attributeValuesOptions[`attribute_${attributeIdFilter}`]"
            :reduce="val => val.value"
            :clearable="true"
            :input-id="'attribute_value_id'"
            @input="(value) => $emit('update:attributeValueIdFilter', value)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label for="stocks">{{ $t('form.stocks.label') }}</label>
          <v-select
            id="stocks"
            v-model="stockFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="stocksOptions"
            :reduce="val => val.value"
            :clearable="true"
            :input-id="'stocks'"
            @input="(value) => $emit('update:stockFilter', value)"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label for="status">{{ $t('administration.table.fields.status') }}</label>
          <v-select
            id="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label for="indexing_status">{{ $t('administration.table.fields.stock_status') }}</label>
          <v-select
            id="indexing_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="inStockFilter"
            :options="inStockOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:inStockFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label for="indexing_status">{{ $t('administration.table.fields.need_manage') }}</label>
          <v-select
            id="need_manage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="needManageFilter"
            :options="manageOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:needManageFilter', val)"
          />
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import _ from 'lodash'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'
import vSelect from 'vue-select'
import StatusMixin from '@/mixins/StatusMixin'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    Treeselect,
    vSelect,
  },
  mixins: [CategoryMixin, StatusMixin],
  props: {
    categoryFilter: {
      type: [Number, null],
      default: null,
    },
    attributeIdFilter: {
      type: [Number, null],
      default: null,
    },
    attributeValueIdFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    needManageFilter: {
      type: [String, null],
      default: null,
    },
    indexingStatusFilter: {
      type: [String, null],
      default: null,
    },
    inStockFilter: {
      type: [Number, null],
      default: null,
    },
    stockFilter: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      attributesOptions: [],
      attributeValuesOptions: [],
      categoryOptions: [],
      stocksOptions: [],
      manageOptions: [
        { label: this.$t('general.need_manage.allneeded'), value: 'allneeded' },
        { label: this.$t('general.need_manage.images'), value: 'images' },
        { label: this.$t('general.need_manage.categories'), value: 'categories' },
        { label: this.$t('general.need_manage.attributes'), value: 'attributes' },
      ],
      inStockOptions: [
        { label: this.$t('general.stock_statuses.available'), value: 1 },
        { label: this.$t('general.stock_statuses.not_available'), value: 0 },
      ],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/administration/categories', {
      params: {
        type: 'product',
        status: 'enabled',
      },
    })
      .then(response => {
        this.categoryOptions = response.data
      })

    await this.$http.get('/api/stocks', {
      params: {
        status: 'enabled',
      },
    })
      .then(response => {
        this.stocksOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    this.$http.get('/api/attributes', {
      params: {
        sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 200, relations: 'values.translations',
      },
    })
      .then(response => {
        this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')

        const vm = this

        _.each(response.data.data, item => {
          vm.attributeValuesOptions[`attribute_${item.id}`] = vm.$options.filters.transformForVSelect(item.values, 'id', 'title')
        })
      })
  },
  methods: {
    async loadAttributeValue(id) {
      if (!id) {
        this.attributeValueIdFilter = null
        this.$emit('update:attributeValueIdFilter', null)
        return
      }

      if (this.attributeValuesOptions && _.has(this.attributeValuesOptions, `attribute_${id}`)) {
        return
      }

      await this.$http.get(`/api/attributes/${id}/values`).then(response => {
        this.attributeValuesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
